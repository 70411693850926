import { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Signup = lazy(() => import("./pages/Signup"));
const SignOut = lazy(() => import("./pages/SignOut"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Services = lazy(() => import("./pages/Services"));
const OurWork = lazy(() => import("./pages/OurWork"));
const Team = lazy(() => import("./pages/Team"));
const Loader = lazy(() => import("./components/Loader"));

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, [location]);

  return (
      <Suspense
        fallback={
          loading ? (
            <div
              className="flex justify-center items-center min-h-screen"
              style={{ backgroundColor: "#101010" }}
            >
              <Loader />
            </div>
          ) : null
        }
      >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route path="/signout" element={<SignOut />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/ourwork" element={<OurWork />} />
          <Route path="/team" element={<Team />} />
        </Routes>
        <ToastContainer />
      </Suspense>
  );
}

export default App;
